





















































import { Component, Prop, Ref } from "vue-property-decorator";
import TranslatableComponent, {
  Translatable,
} from "@/lib/mixins/translateable";

@Component({})
@Translatable("form_components")
export default class FileUpload extends TranslatableComponent {
  @Ref() readonly inputElement!: HTMLInputElement;

  @Prop()
  public name!: string;

  @Prop()
  public label?: string;

  @Prop()
  public buttonLabel?: string;

  @Prop()
  public errorMessage?: string;

  @Prop({ default: false })
  public required?: boolean;

  @Prop()
  public accept?: string;

  private isValid = false;
  private validityChecked = false; //becomes true when trying to submit, false again when value of multiselect changed

  private selectedFiles: File[] = [];

  get computedName(): string {
    return this.name.toLowerCase().split(" ").join("-");
  }

  filesSelected(): void {
    this.validityChecked = false;

    this.selectedFiles = [];

    if (this.inputElement.files) {
      for (let i = 0; i < this.inputElement.files.length; i++) {
        let file: File | null = this.inputElement.files.item(i);

        if (file) {
          this.selectedFiles.push(file);
        }
      }
    }

    this.$emit("input", this.selectedFiles);
  }

  resetValue() {
    this.inputElement.value = "";
    this.filesSelected();
  }

  validate(): boolean {
    this.validityChecked = true;

    if (this.required) {
      this.isValid = this.selectedFiles.length > 0;
    } else {
      this.isValid = true;
    }

    return this.isValid;
  }
}
