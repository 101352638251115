



































































































import { Component } from "vue-property-decorator";
import TranslatableComponent, {
  Translatable,
} from "@/lib/mixins/translateable";
import FormInputField from "@/components/form-components/FormInputField.vue";
import VForm from "@/components/VForm.vue";
import FileUpload from "@/components/form-components/FileUpload.vue";
import { Account } from "@/interfaces/Account";
import djlMultiSelect from "@/components/form-components/djlMultiselect.vue";
import mFiles from "@/api/mFiles";
import accessControl from "@/api/access_control";
import { AxiosResponse } from "axios";
import Loader from "@/components/Loader.vue";
import Page from "@/components/layouts/Page.vue";
import TheHeader from "@/components/TheHeader.vue";
import { accountLabel } from "@/utils/accountDisplayFormatter";

export interface DossierForm {
  account: Account | null;
  title: string;
  period: string;
  files: File[];
}

@Component({
  methods: { accountLabel },
  components: {
    Page,
    TheHeader,
    VForm,
    FormInputField,
    djlMultiSelect,
    FileUpload,
    Loader,
  },
})
@Translatable("dossier")
export default class DossierUpload extends TranslatableComponent {
  isShowMFilesDmsFileUpload: boolean = false;

  private formLoading: boolean = false;
  private isLoading: boolean = true;

  private accountList: Account[] = [];

  private dossierForm: DossierForm = {
    account: null,
    title: "",
    period: "",
    files: [],
  };

  mounted() {
    accessControl.accounts
      .accounts()
      .then((response: AxiosResponse<Account[]>) => {
        this.accountList = response.data;
      })
      .catch(() => {
        this.$snotify.error(
          this.translated_view_value("error.no_accounts.notify_message")
        );
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  submitHandler() {
    return new Promise((resolve, reject) => {
      this.formLoading = true;

      const formData = new FormData();
      formData.append("title", this.dossierForm.title);
      formData.append("period", this.dossierForm.period);

      this.dossierForm.files.forEach((file) => {
        formData.append("file", file);
      });

      mFiles
        .addDocument(this.dossierForm.account?.id || "", formData)
        .then((response) => {
          this.formLoading = false;
          this.$snotify.success(
            this.translated_view_value("form.success_message")
          );
          this.$router.push({
            name: "dossier",
          });
          resolve(response);
        })
        .catch((error) => {
          this.formLoading = false;
          this.$snotify.error(this.translated_view_value("form.error_message"));
          reject(error);
        });
    });
  }

  cancelClicked() {
    this.$router.push({
      name: "dossier",
    });
  }
}
